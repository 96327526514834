<template>
    <el-dialog
        v-model="show"
        title="新增技师"
        width="700px"
    >
        <div class="user-member-dialog">
            <div class="basic-info">
                <el-form label-position="left" label-width="100px">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="所属职位：">
                                <el-select
                                    disabled
                                    v-model="roleId"
                                    placeholder="请选择所属职位"
                                >
                                    <el-option
                                        v-for="(item,index) in roleList"
                                        :key="index"
                                        :label="item.title"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="员工姓名：">
                                <el-input v-model="memeberDetail.realname" placeholder="请输入员工姓名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="员工账号：">
                                <el-input v-model="memeberDetail.username" placeholder="请输入员工账号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="手机号：">
                                <el-input v-model="memeberDetail.phone" placeholder="请输入手机号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="邮箱：">
                                <el-input v-model="memeberDetail.email" placeholder="请输入邮箱"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="登录密码：">
                                <el-input v-model="memeberDetail.password" placeholder="请输入登录密码"
                                          type="password"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="确认密码：">
                                <el-input v-model="memeberDetail.passwordConfirmation" placeholder="请确认登录密码"
                                          type="password"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
        <template #footer>
            <el-button @click="show = false">取消</el-button>
            <el-button type="primary" @click="add">新增</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {onMounted, reactive, toRefs, watch} from "vue";
import {ADD_USER_DETAIL, GET_DEPARTMENTS_LIST, GET_ROLES_LIST} from "@/api/dataProvider";
import {ElMessage} from "element-plus";

export default {
    name: "TechnicianAdd",
    props: {
        show: Boolean,
    },
    emits: ["updateList", "update:show"],
    setup(props, context) {
        let state = reactive({
            roleId: 4,
            memeberDetail: {},
            departmentList: [],
            roleList: [],
        })

        onMounted(() => {
            init()
        })

        watch(() => props.show, (val) => {
            context.emit("update:show", val);
        })

        // 数据操作
        let init = () => {
            getDepartmentList().then((res) => {
                return getRoleList()
            }).then((res) => {
            })
        }

        // 获取部门列表
        let getDepartmentList = () => {
            return new Promise((resolve) => {
                GET_DEPARTMENTS_LIST(
                    {
                        size: -1
                    },
                    (res) => {
                        if (res.data.status === 200) {
                            state.departmentList = res.data.data
                        }
                        resolve()
                    }
                )
            })
        }

        // 获取职位列表
        let getRoleList = () => {
            return new Promise((resolve) => {
                GET_ROLES_LIST(
                    {
                        size: -1
                    },
                    (res) => {
                        if (res.data.status === 200) {
                            state.roleList = res.data.data
                        }
                        resolve()
                    }
                )
            })
        }

        // 新增
        let add = () => {
            ADD_USER_DETAIL(
                {
                    ...state.memeberDetail,
                    roleId: [state.roleId]
                },
                (res) => {
                    if (res.data.status === 200) {
                        ElMessage({
                            type: "success",
                            message: "新增成功!"
                        });
                        updateList()
                    } else {
                        ElMessage({
                            type: "error",
                            message: "新增失败，请重试！"
                        });
                    }
                }
            )
        }

        // 更新父级列表
        let updateList = () => {
            context.emit("updateList");
            context.emit("update:show", false);
        }

        return {
            ...toRefs(state),
            init,
            getDepartmentList,
            getRoleList,
            add,
            updateList
        }
    },
}
</script>

<style lang="scss" scoped>
.user-member-dialog {
    .el-form {
        .el-form-item {
            .el-select {
                width: 100%;
            }
        }
    }
}
</style>