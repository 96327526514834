<template>
    <div class="technician-list">
        <div class="search-toolbar">
            <div class="tool-item">
                <span class="item-label">技师姓名：</span>
                <el-input class="item-input" placeholder="请输技师姓名"></el-input>
            </div>
            <div class="tool-item tool-buttons">
                <el-button type="primary">搜索</el-button>
                <el-button>重置</el-button>
            </div>
        </div>
        <div v-loading="technicianListLoading" class="table-container">
            <div class="buttons-wrapper">
                <el-button type="primary" @click="openAddDialog()">新建</el-button>
                <!--<el-button type="danger">删除</el-button>-->
            </div>
            <div class="table-wrapper">
                <el-table
                    :cell-style="{textAlign:'center'}"
                    :data="technicianList"
                    :header-cell-style="{background:'#409EFF',color:'#fff',textAlign:'center'}"
                    @selection-change="getTechnicianSelection"
                >
                    <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        label="技师姓名"
                        prop="realname"
                    >
                    </el-table-column>
                    <el-table-column
                        label="联系方式"
                        prop="phone"
                    >
                    </el-table-column>
                    <el-table-column
                        label="车牌号"
                    >
                        <template #default="scope">
                            {{ scope.row.cars && scope.row.cars.length > 0 ? scope.row.cars[0].number : null }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="车辆类型"
                    >
                        <template #default="scope">
                            {{
                                scope.row.cars && scope.row.cars.length > 0 ? getLabel($store.state.task.carTypeList, scope.row.cars[0].type) : null
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="注册时间"
                        prop="createdAt"
                    >
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination-wrapper">
                <el-pagination
                    :current-page="page.page"
                    :page-size="page.size"
                    :total="technicianTotal"
                    background
                    layout="sizes,prev, pager, next,jumper"
                    @current-change="changePageCurrent"
                    @size-change="changePageSize"
                >
                </el-pagination>
            </div>
        </div>
    </div>
    <!--新增弹窗-->
    <TechnicianAdd v-model:show="addDialog.show" @updateList="getTechnicianList"></TechnicianAdd>
</template>

<script>
import {onMounted, reactive, toRefs} from "vue";
import {ElMessage} from "element-plus";
import {GET_USER_LIST} from "../../api/dataProvider";
import TechnicianAdd from "./TechnicianAdd.vue"

export default {
    name: "TechnicianList",
    components: {
        TechnicianAdd
    },
    setup() {
        let state = reactive({
            technicianListLoading: false,
            technicianList: [],
            technicianSelection: [],
            // 分页信息
            technicianTotal: 0,
            page: {
                page: 1,
                size: 10,
            },
            // 详情弹窗
            addDialog: {
                show: false,
            },
        })

        onMounted(() => {
            getTechnicianList()
        })

        // 获取技师列表
        let getTechnicianList = () => {
            state.technicianListLoading = true
            GET_USER_LIST(
                {
                    ...state.page,
                    roleId: 4
                },
                (res) => {
                    if (res.data.status === 200) {
                        state.technicianList = res.data.data
                        state.technicianTotal = res.data.page.total
                        console.log(res.data.data)
                    } else {
                        ElMessage({
                            message: "列表获取失败，请刷新重试！",
                            type: "error"
                        });
                    }
                    state.technicianListLoading = false
                }
            )
        }

        // 修改分页当前页
        let changePageCurrent = (page) => {
            state.page.page = page
            getTechnicianList()
        }

        // 修改分页size
        let changePageSize = (size) => {
            state.page.size = size
            getTechnicianList()
        }

        // 获取列表选中id列表
        let getTechnicianSelection = (arr) => {
            let idArr = []
            arr.map((item) => {
                idArr.push(item.id)
            })
            state.technicianSelection = idArr
        }

        // 打开新增弹窗
        let openAddDialog = () => {
            state.addDialog.show = true
        }

        // 获取label方法
        let getLabel = (list, value) => {
            let label
            list.map((item) => {
                if (item.value === value) {
                    label = item.label
                }
            })
            return label
        }

        return {
            ...toRefs(state),
            getTechnicianList,
            getTechnicianSelection,
            changePageCurrent,
            changePageSize,
            openAddDialog,
            getLabel,
        }
    },
}
</script>

<style lang="scss" scoped>
.technician-list {

}
</style>